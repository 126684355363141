<template>
  <div class="">
      <div class="relative z-50" v-if="!isValidToken && firstLoading">
        <div v-if="jobDetails !== null" class="p-2 m-1 mt-0 pt-12">
          <div class="relative">
            <div class="card top_primary_card bg-primary font-bold text-white p-3 rounded-xl absolute -top-8 w-full heading-2">
              #{{jobDetails.jobNumber}}
            </div>
          </div>
          <div class="mt-10 xl:grid grid-cols-2 ">
            <div class="card rounded-lg bg-white p-2 m-2" v-if="jobDetails !== null">
              <div class="">
                <div class="  mb-4 grid grid-cols-12">
                  <div class="col-span-6">
                    <p class="text-primary heading-2 font-bold">{{OrgDetail.orgName}}</p>
                    <p class="text-text2">Tax No: {{OrgDetail.taxNumber}}</p>
                    <p class="text-text2">
                      {{OrgDetail.addressLine1 !== '' ? OrgDetail.addressLine1 +',' : ''}} {{OrgDetail.addressLine2 !== '' ? OrgDetail.addressLine2 + ',' : ''}}
                      </p>
                      <p class="text-text2">
                        {{OrgDetail.city !== '' ? OrgDetail.city + ',' : ''}} {{OrgDetail.state}} 
                      </p>
                      <p v-if="OrgDetail.country !== ''" class="text-text2">
                        {{OrgDetail.country !== '' ? OrgDetail.country + ',' : ''}} {{ OrgDetail.zip }}
                      </p>
                  </div>
                  <div class="col-span-6 text-right ">
                    <Button class="my-1" :isSqure="true" :btnSize="'small'" style="letter-spacing: 1.4px;" :textColor="'white'" :btnColor="'primary'" :btnText="'DOWNLOAD'" @buttonAction="downloadInvoice()"/>
                  </div>
                </div>
                <div>
                  <div class="divider"></div>
                    <div class=" text-gray3 my-2 items-center">
                      <div class="">
                        <p class="text-text2 heading-2 font-bold pb-2">{{jobDetails.jobTitle}}</p>
                        <div class="">
                            <!-- <span class="flex items-center" v-if="jobDetails.companyName !== ''">
                              <p class="text-gray4">Company:</p>
                              <p class="text-text2 pl-2">{{jobDetails.companyName}}</p>
                            </span> -->
                            <span class="flex items-center" v-if="jobDetails.customerName !== ''">
                              <p class="text-text1 font-semibold">Customer Name:</p>
                              <p class="text-text1  pl-2">{{jobDetails.customerName}}</p>
                            </span>
                            <span class="flex item-center">
                              <p class="flex flex-wrap text-text2 whitespace-pre-line" v-if="jobDetails.fullAddress === ''">
                                {{jobDetails.addressLine1}}
                              </p>
                              <p class="flex flex-wrap text-text2 whitespace-pre-line" v-if="jobDetails.fullAddress !== ''">
                                {{jobDetails.fullAddress}}
                              </p>
                              <div>
                                <span @click="openMap(jobDetails.latitude, jobDetails.longitude)" class=" cursor-pointer text-primary "><i class="fa-solid fa-location-dot w-8"></i></span>
                              </div>
                            </span>
                            <div class="flex items-center">
                              <span class="flex items-center" v-if="jobDetails.houseNo !== ''">
                                <p class="text-gray4">#:</p>
                                <p class="text-text2 pl-2">{{jobDetails.houseNo}}, </p>
                              </span>
                              <span class="flex items-center" v-if="jobDetails.floorNumber !== ''">
                                <p class="text-gray4 pl-1"> (Floor:</p>
                                <p class="text-text2 pl-2">{{jobDetails.floorNumber}})</p>
                              </span>
                            </div>
                            <span class="flex items-center" v-if="jobDetails.buildingName !== ''">
                              <p class="text-gray4">Bldg:</p>
                              <p class="text-text2 pl-2">{{jobDetails.buildingName}}</p>
                            </span>
                            <p class="text-text2">
                              {{jobDetails.addressLine1 !== '' ? jobDetails.addressLine1 + ',' : ''}} {{jobDetails.addressLine2 !== '' ? jobDetails.addressLine2 + ',' : ''}}
                            </p>
                            <p class="text-text2">
                              {{jobDetails.city !== '' ? jobDetails.city + ',' : ''}} {{jobDetails.state}} {{ jobDetails.zip }}
                            </p>
                            <p v-if="jobDetails.country !== ''" class="text-text2">
                              {{jobDetails.country}}
                            </p>
                            <span class="flex items-start" v-if="jobDetails.directionNote !== ''">
                              <p class="text-gray4">Directions:</p>
                              <p class="text-text2 pl-2">{{jobDetails.directionNote}}</p>
                            </span>
                          </div>
                      </div>
                    </div>
                </div>
                <div >
                  <div class="" v-if="jobDetails.jobDescription !== ''">
                    <div class="divider mb-2"></div>
                      <div class=" text-gray3 my-2 items-center">
                        <p class="text-text1 font-semibold">Job Instructions:</p>
                        <p class="text-text2">{{jobDetails.jobDescription}}</p>
                      </div>
                  </div>
                </div>
                <div >
                  <div class="" v-if="jobDetails.visitList.length > 0">
                    <div class="divider mb-2"></div>
                    <div class=" flex table_containder" style="flex-flow:wrap;">
                      <div class="text-gray4 heading-5">{{ jobDetails.visitList.length > 0 ? 'Visits' : 'Visit' }} ({{jobDetails.noOfVisit}}):</div>
                      <div class="p-2 w-full rounded my-1 card hover:bg-gray-100 cursor-pointer" v-for="(name, index) in jobDetails.visitList" :key="index" @click="redirectToDetail(name)">
                        <div class="my-1 flex items-center" >
                          <div class=" heading-5 text-text2 font-semibold pr-2">
                            {{index + 1}}.
                          </div>
                          <div class="heading-5 text-text2 font-semibold">
                            <span v-html="dateFilter(name.visitStartDateTime, 'scheduleDate')" ></span>
                            <span>(</span><span v-html="dateFilter(name.visitStartDateTime, 'scheduleTime')" ></span>
                            <span>-</span>
                            <span v-html="dateFilter(name.visitEndDateTime, 'scheduleTime')" ></span><span>)</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="divider my-2"></div> -->
              </div>
            </div>
            <div class="col-span-1 ">
              <div class="card rounded-lg bg-white p-2 m-2">
                <div class="text-gray4 heading-5 pb-2">Notes:</div>
                    <div v-if="noteLIst.length > 0">
                      <div class="mb-4 text-text1 note_icn_box" v-for="(note,i) in noteLIst" :key="i">
                          <div class="flex items-center justify-between mb-2">
                            <div class=""><span class="text-gray4 heading-6">Added By: </span> 
                                <span class="">{{note.firstName.charAt(0).toUpperCase() + note.firstName.slice(1)}} </span>
                                <span class="">{{note.lastName.charAt(0).toUpperCase() + note.lastName.slice(1)}}</span>
                            </div>
                            <div class="flex items-center gap-2">
                              <span class="text-gray4 heading-6">{{note.addedDate | dateManyAgo }}</span>
                            </div>
                          </div>
                          <div class="mb-2">
                              <div>
                                <span class="text-gray4 heading-6">Note: </span>
                                <span class="mb-0 whitespace-pre-line">
                                  {{note.notes === '' ? '-' : (note.notes.length > 125)? note.readMoreNote ?  note.notes : note.notes.substr(0, 125) : note.notes}}
                                  <span v-if="note.notes.length > 125" class="text-primary cursor-pointer heading-7" @click="note.readMoreNote = !note.readMoreNote">
                                    {{note.readMoreNote === false ? 'Read More...' : 'Read Less...'}}</span>
                                </span>
                              </div>
                          </div>
                          <div v-if="note.attachmentList.length > 0" class="">
                            <p class="text-primary heading-6 mb-2">Attachments:</p>
                            <div class="flex gap-4" style="flex-wrap: wrap;">
                              <div class=" card rounded-xl border-t border-gray-100 bg-white attachment-card cursor-pointer group" style="width: 120px; height: 80px"  v-for="(attach, a) in note.attachmentList " :key="a" @click="showFullImage(attach)">
                                  
                                  <div v-if="attach.docType === 'image'" class="text-center">
                                      <img style="height: 60px;width: 120px;object-fit: contain;" :src="`${pathPrefix}${attach.thumbPath}/image200x200.jpg`" class="scroll-img-view">
                                      <div class="invisible group-hover:visible absolute bg-gray4 w-auto min-w-max card rounded-md p-1.5 px-3 text-white opacity-70 zIndex heading-5">{{ attach.displayFileName }}</div>
                                  </div>
                                  <div v-if="attach.docType === 'video'" class="flex justify-center items-center w-full">
                                      <img style="height: 60px;width: 120px;object-fit: contain;" :src="`${pathPrefix}${attach.thumbPath}/image200x200.jpg`" class="scroll-img-view">
                                      <img class="flex items-center  text-4xl absolute text-white " height="35px" width="35px" src="@/assets/images/play.svg" alt="">
                                      <div class="invisible group-hover:visible w-auto min-w-max -bottom-7 absolute bg-gray4 card rounded-md p-1.5 px-3 text-white opacity-70 zIndex">{{ attach.displayFileName }}</div>
                                  </div>
                                  <div v-if="attach.docType === 'pdf'" class="flex justify-center items-center w-full">
                                      <div style="" class="flex items-center text-primary text-4xl"><i class="fa-regular fa-file-pdf"></i></div>
                                      <div class="invisible group-hover:visible w-auto min-w-max -bottom-7 absolute bg-gray4 card rounded-md p-1.5 px-3 text-white opacity-70 zIndex">{{ attach.displayFileName }}</div>
                                  </div>
                                  <div v-if="attach.docType !== 'image' && attach.docType !== 'video' && attach.docType !== 'pdf'" class="flex justify-center items-center w-full">
                                      <div style="" class="flex items-center text-primary text-4xl"><i class="fa-regular fa-file"></i></div>
                                      <div class="invisible group-hover:visible w-auto min-w-max -bottom-7 absolute bg-gray4 card rounded-md p-1.5 px-3 text-white opacity-70 zIndex">{{ attach.displayFileName }}</div>
                                  </div>
                                </div>
                            </div>
                          </div>
                          <div v-if="i + 1 !== noteLIst.length" class="divider my-4"></div>
                      </div>
                  </div>
                  <div v-else>
                    <div class="border text-center rounded border-gray2  flex items-center relative">
                      <div class="text-gray4 heading-2 px-5">
                        <i class="fa-solid fa-triangle-exclamation"></i>
                      </div>
                      <div class="justify-center">
                        <div class="text-text2 heading-5">No Notes</div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
     </div>
     <div v-if="isValidToken || ShowErr" class="popup_overlay">
        <div class="bg-gray-200 w-full px-2 md:px-0 h-screen flex items-center justify-center">
          <div class="bg-white border border-gray-200 flex flex-col items-center justify-center px-4 md:px-8 lg:px-20 py-8 rounded-lg shadow-2xl">
              <p class="text-6xl md:text-7xl lg:text-6xl font-bold tracking-wider text-gray-300"><i class="fa-solid fa-triangle-exclamation fa-fade"></i></p>
              <p class="text-gray-500 mt-3 py-2 border-y-2 text-center">Please Check Your Link and Try Again Later</p>
          </div>
        </div>
      </div>
     <div v-if="isExpired">
        <EmailVarified />
     </div>
     <FullImage v-if="shoFullImage" :fileVaultId="fileVaultId" :fileType="fileType" :fileName="fileName" />  
  </div>
</template>
<script>
import FullImage from '../components/FullImage.vue'
var moment = require('moment')
import MyJobApp from '@/api/MyJobApp.js'
import Button from '@/View/components/globalButton.vue'
import EmailVarified from '@/View/endUserNewFlow/components/emailVarified.vue'
export default {
  components: {
    Button,
    FullImage,
    EmailVarified
  },
  data() {
    return {
      isValidToken: false,
      isExpired: false,
      fileVaultId: 0,
      fileType: '',
      fileName: '',
      shoFullImage: false,
      totalVisitCount: 0,
      pathPrefix: '',
      noteLIst: [],
      sessionToken: '',
      linkHash: '',
      OrgDetail: {},
      jobDetails: null,
      firstLoading: false,
      ShowErr: false,
      screenWidth: 0,
      cardHeight: window.innerHeight - 140
    };
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  mounted() {
    document.title = ''
    this.sessionToken = this.$route.params.customerToken
    this.linkHash = this.$route.params.jobToken
    this.myEventHandler()
    this.$root.$on('fullImageHandlerEnd', (response) => {
        this.shoFullImage = response
    })
    this.$root.$on('tokenResponse', (isTokenExpired, isTokenInvalid) => {
      if (!isTokenExpired && !isTokenInvalid) { 
        this.getJobDetail()
      } else if (isTokenExpired && !isTokenInvalid) {
        this.isExpired = true
        this.isValidToken = false
        this.firstLoading = true
      } else if (!isTokenExpired && isTokenInvalid) {
        this.isExpired = false
        this.isValidToken = true
        this.firstLoading = true
      }
    })
    this.$root.$emit('tokenVarificationApi', this.$route.params.customerToken)
  },
  beforeDestroy() {
    this.$root.$off('tokenResponse')
  },
  methods: {
    getJobDetail () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.GetJobDetailLinkHash(
        this.sessionToken,
        this.linkHash,
        response => {
          this.ShowErr = false
          this.firstLoading = true
          this.jobDetails = response.Data !== null ? response.Data : {}
          this.OrgDetail = response.Data.organizationDetail !== null ? response.Data.organizationDetail : {}
          document.title = 'Visit: ' + this.jobDetails.jobTitle
          this.getNotesList()
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        (error) => {
          this.ShowErr = true
          this.firstLoading = false
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    getNotesList () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.GetEndUserJobNotesList(
        this.sessionToken,
        this.linkHash,
        0,
        100,
        'job',
        response => {
          this.pathPrefix = response.Data.pathPrefix
          this.totalVisitCount = response.count
          let templist = []
            if (response.Data.noteList !== null) {
                response.Data.noteList.map(data=> {
                   let attachmentList = JSON.parse(data.attachments).attachmentList

                   let attach = []

                    if (attachmentList !== null) {
                        attachmentList.map(a => {
                            let temp = a.fileType.split('/')
                            console.log('temp', temp)
                            let type = ''
                            if (temp[0] === 'image' ) {
                                type = 'image'
                            } else if (temp[0] === 'application' && temp[1] === 'pdf') {
                              type = 'pdf'
                            } else if (temp[0] === 'video' ) {
                              type = 'video'
                            }
                            attach.push({...a, docType: type})
                        })
                    }

                    templist.push({...data, charLimit: 120, attachmentList: attach, readMoreNote: false})
                })      
            }
            this.noteLIst = templist
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        (error) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    redirectToDetail (data) {
      this.$router.push({name: 'EndUserVisitDetail', params: {customerToken: this.sessionToken, visitToken: data.visitLinkHash}})
    },
    openMap (lat, lng) {
     let url = 'https://www.google.com/maps/search/?api=1&query=' + lat + ',' + lng
      window.open(url, '_blank')
    },
    showFullImage (data) {
        this.fileVaultId = data.fileVaultId
        this.fileType = data.docType
        this.fileName = data.displayFileName
        this.shoFullImage = true
    },
    dateFilter (dateTime, value) {
      let checkDate = new Date(dateTime)
      let timeValue = moment(checkDate, 'ddd DD-MMM-YYYY, hh:mm a').format('hh:mm a')
      let todayDate = new Date().setHours(0, 0, 0, 0)
      var fullDate = moment(dateTime).format('MMM DD, ')
      let dayName = moment(dateTime).format('ddd')
      dateTime = new Date(dateTime).toUTCString()
      let requestDt = dateTime
      requestDt = new Date(requestDt).setHours(0, 0, 0, 0)
      if (value === 'scheduleDate') {
        return fullDate + ' ' + dayName
      } else if (value === 'scheduleTime') {
        return timeValue
      } else {
        if (moment(todayDate).diff(requestDt, 'days') === 0) {
          return 'Today ' + timeValue
        }
        let difDay = moment(todayDate).diff(requestDt, 'days')
        if (difDay > 1) {
          return fullDate + ' ' + timeValue
        }
        if (difDay === 1) {
          return 'Yesterday ' + timeValue
        }
      }
    },
    downloadInvoice () {
      // this.$store.dispatch('SetAlert', {showAlert: true, message: 'Download Option Not Available Right Now', color: 'error'})
      this.$router.push({name: 'DownloadHashJob', params: {hashId: this.linkHash}})
    },
    myEventHandler () {
      this.screenWidth = window.innerWidth
    }
  },
};
</script>
<style scoped>
.hash_col {
      min-width: 40px !important;
      width: 80px !important;
      overflow-wrap: anywhere;
      white-space: pre-wrap;
      padding-right: 8px;
    }
    .desc_col {
      min-width: 40px !important;
      width: 360px !important;
      overflow-wrap: anywhere;
      white-space: pre-wrap;
      padding-right: 8px;
    }
    .rate_col {
      min-width: 40px !important;
      width: 190px !important;
      overflow-wrap: anywhere;
      white-space: pre-wrap;
      padding-right: 8px;
    }
    .qty_col {
      min-width: 40px !important;
      width: 150px !important;
      overflow-wrap: anywhere;
      white-space: pre-wrap;
      padding-right: 8px;
    }
    .tax_col {
      min-width: 80px !important;
      width: 230px !important;
      overflow-wrap: anywhere;
      white-space: pre-wrap;
      padding-right: 8px;
    }
    .detail_col {
      min-width: 40px !important;
      width: 150px !important;
      overflow-wrap: anywhere;
      white-space: pre-wrap;
      padding-right: 8px;
    }
    .allTax_col {
      min-width: 40px !important;
      width: 230px !important;
      overflow-wrap: anywhere;
      white-space: pre-wrap;
      padding-right: 8px;
    }
    .loader {
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    border-top: 8px solid #3498db;
    width: 60px;
    height: 60px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  .attachment-card {
    display: flex;
    align-items: center;
    position: relative;
  }
  .zIndex {
  z-index: 100 !important;
}
</style>